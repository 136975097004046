*{
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}
html{
  scroll-behavior: smooth;
}
body{
  scroll-behavior: smooth;
}
:root{
  /* --primary-color: #00CECB; */
  /* --primary-color: #279AF1; */
  --primary-color: #253C78;
  --title-color: #fff;
  --white-color2: #f1f1f1;
  --acento-color: #dbd8d8;
  --dark-color: #464949;
  --dark-color2: #314447;
  --hover-color: #d1c6b7;
  /* --text-color-sobre: #7A7A7A; */
  --text-color-sobre: #131112;
  --swiper-navigation-color:#ccc;
  --swiper-pagination-color:#ccc;
}

.swiper-slide{
  background-color: var(--white-color2);
}
