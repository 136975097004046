.slidercontainer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
}
.slidercontainer img{
    object-fit: cover;
    width: 100%;
    height: 400px;
}
.slidetext{
    font-family: Poppins;
    height: 0;
    position: absolute;
    color: #fff;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
}

.slidetext span{
    display: flex;
    justify-content: center;
    align-items: center;
    word-wrap: break-word;
    color:#fff;
}
.boxblack{
    background-color: black;
    position: absolute;
    width: 100%;
    height: 0;
    opacity: 0;
    transition: ease-in-out 0.5s;
    bottom: 0;
}
.slidercontainer:hover .boxblack {
    opacity: 0.3;
    height: 100%;
    transition: ease-in-out 0.5s;
}

.slidercontainer:hover .slidetext {
    margin-bottom: 1rem;
    opacity: 1;
    height: 40%;
    transition: ease-in-out 0.5s;
}


@media only screen and (max-width: 800px) {
    .slidetext{
        display:flex;
        flex-direction: column;
        transition: ease-in-out 0.5s;
        font-size: 10px;
        width: 100%;
    }
    
    .slidercontainer:hover .slidetext {
        opacity: 1;
        transition: ease-in-out 0.5s;
    }

}