.footer {
    width: 100%;
    background-color: #1e212b;
    color: #FFF;
    height: auto;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-block: 5rem;
    font-family: Poppins;
    gap: 1rem;
}

.sectionimg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.sectionimg p{
    font-size: 14px;
}
.sectionimg img{
    width: 177px;
    height: 75px;
}

.sectioncontact {
    display: flex;
    flex-direction: column;
}
.sectioncontact p{
    font-size: 16px;
}

.sectionsocial {
    display: flex;
    flex-direction: column;
}

.sectionsocial ul{
    gap: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

}
.sectionsocial ul li{
    border-radius: 100%;
    width: 46px;
    height: 46px;
    list-style: none;
    background-color: var(--title-color);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.7;
    transition: ease-in-out 0.5s;

}
.sectionsocial ul li:hover{
    opacity: 1;
    transition: ease-in-out 0.5s;
    /* transform: scale(1.1); */
}
.sectionsocial ul li a{
    display: flex;
    justify-content: center;
    color: var(--primary-color);
    font-size: 25px;
}

.credits{
    text-align: center;
    background-color: var(--white-color2);
    color: var(--primary-color);
    font-family: Roboto;
    font-weight: 400;
    font-size: 14px;
    padding-block: 2rem;
}

.credits a{
    text-decoration: none;
    color: var(--primary-color);
    font-weight: 1000;
}

@media only screen and (max-width: 900px){
    .footer {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .sectioncontact{
        justify-content: center;
        align-items: center;
    }
    .sectionimg p{
        text-align: center;
        width: 50%;
    }
}