.containersobre {
    width: 80%;
    display: flex;
    flex-direction: column;
    height: auto;
    min-height: 800px;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    text-align: center;
}
.sobremim{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    text-align: center;

}
.containersobre h2 {
    text-align: center;
    font-family: Poppins;
    color: var(--dark-color);
}
.containersobre p{
    text-align: center;
    font-family: Poppins;
    margin: 0.5rem;
    color: var(--text-color-sobre);
}
.containersobre h1{
    text-align: center;
    font-family: 'Baloo 2', sans-serif;
    color: var(--primary-color);
    font-weight: 800;
    font-size: 3rem;
}
.sobreimg{
    background: rgb(37,60,120);
    background: linear-gradient(90deg, rgba(37,60,120,1) 50%, rgba(84,115,195,1) 100%);
    width: 400px;
    height: 400px;
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.sobreimg img{
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    width: 395px;
    height: 395px;
    object-fit:cover;
    object-position: 10% 30%;
}
.textsobre{
    width: 40%;
}
/* BOXES ESPECIALIADES */
.boxsobrecontainer{
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}
.boxsobreitemcontainer{
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}
.boxsobreitem{
    scrollbar-width: thin;
    scrollbar-color: black transparent;
    border-radius: 10px 10px 10px 10px;
    width: 30%;
    height: auto;
    max-height: 560px;
    overflow:scroll;
    overflow-x: hidden;
    padding: 1rem;
    min-height: 350px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    transition: ease-in-out 0.5s;
    transform: scale(1.0);
}
.boxsobreitem::-webkit-scrollbar {
    width: 3px;
    height: 3px; /* A altura só é vista quando a rolagem é horizontal */
}
.boxsobreitem::-webkit-scrollbar-track {
    background: transparent;
    padding: 2px;
}
.boxsobreitem::-webkit-scrollbar-thumb {
    
    background-color: #ccc;
}
.boxsobreitem:hover{
    transform: scale(1.05);
    transition: ease-in-out 0.5s;
}
.boxsobreitemtitle{ 
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    word-wrap: break-word;
    padding-top: 0.5rem;
    
}
.boxsobreitem p{
    color: #000;
    font-family: Poppins;
    font-weight: 300;
}
.boxsobreitem h2{
    font-family: Poppins;
    font-size: 1.2rem;
    color: #000;
}

.boxsobreitem:nth-child(1){
    background-color:#F5B700;
}
.boxsobreitem:nth-child(2){
    background-color: #FF0066;
}
.boxsobreitem:nth-child(3){
    background-color:#008BF8;
}





@media only screen and (max-width:900px) {
    .containersobre {
        flex-direction: column;
        /* min-height: auto;
        min-height: none; */
        
    }
    .textsobre{
        width: 100%;
    }
    .sobreimg{
        width: 350px;
        height: 350px;
    }
    
    .sobreimg img{
        width: 340px;
        height: 340px;
    }

    /**/
    .boxsobrecontainer{
        color: black;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .boxsobrecontainer h1{
        font-size: 2rem;
    }
    .boxsobreitemcontainer{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        flex-direction: column;
    }
    .boxsobreitem{
        width: 100%;
        max-width: 350px;
    }
    .boxsobreitem h2{
        font-size: 1rem;
    }
    .boxsobreitem p{
        font-size: 0.8rem;
    }
    .sobremim{
        flex-direction: column;
    }
}