.locaiscontainer{
    width: 100%;
    height: 300px;
    background-color: var(--white-color2);
    display: flex;
    justify-content: center;
    align-items: center;
    padding-block: 2rem;
    
}

.separadorcontainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 2rem;
}
.separador{
    border-radius: 3px 3px 3px 3px;
    transform: rotate(45deg);
    width: 20px;
    height: 20px;
    background-color: #d4d4d4;
}
hr{
    width:50px;
    border:1px solid #d4d4d4; 
}
.locaisinfocontainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.locaisinfo{
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* justify-content: center; */
}
.locaisinfo section img{
    width: 200px;
    height: 150px;
    object-fit: cover;
}
.locaisinfo h1{
    left: 0;
    text-align: center;
    font-family: Poppins;
    font-weight: 800;
    font-size: 3rem;
    color: var(--dark-color)
}
.locaisinfo h2 {
    font-family: Poppins;
    color: var(--dark-color);
}
.locaisinfo p{
    font-family: Poppins;
    color: var(--text-color-sobre);
}
.locaiscontainer iframe{
    border: none;
}

.btn {
    border-radius: 5px 5px 5px 5px;
    background-color: green;
    color: white;
    width: 80%;
    height: 40px;
    border: none;
    transition: ease-in-out 0.5s;
    cursor: pointer;
    margin: 0.5rem;
}
.btn:hover{
    transform: scale(1.1);
    transition: ease-in-out 0.5s;
}
.btn a {
    text-decoration: none;
    color: white;
    font-size: 1rem;
    font-family: Poppins;
}
.btn svg {
    font-size: 1rem;
}
.maps{
    width:600px;
    height: 450px;
    padding: 0.5rem;
}
@media only screen and (max-width:900px) {
    .locaiscontainer{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap:3rem;
        
    }
    .locaisinfocontainer{
        gap: 2rem;
    }
    .locaisinfocontainer section{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .locaisinfo h1{
        font-size: 30px;
    }
    .locaisinfo h2{
        font-size: 16px;
    }
    .locaisinfo p{
        font-size: 14px;
    }
    .locaisinfo{
        justify-content: center;
        align-items: center;
    }
    
    .maps{
        width:100%;
        height: 250px;
    }
}